*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    //box-sizing: inherit;
}

body {
    font-family: 'Inter', sans-serif;
    color: #000;
}
body
{
    margin:0px;
    padding:0px;
    width:100%;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }
}


.App{
    // position: relative;
}
.custom-modal {
    
    .modal-dialog {
        
        --bs-modal-width: 441px;
        
        .modal-content {
            border-radius: 20px;
            
            .modal-header {
                margin-top: 10px;
                border-bottom: 0;
            }
            
            
            .modal-body {
                .form {
                    
                    padding: 0 30px 15px 30px;
                    
                    .header {
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    
                    .form-label {
                        text-align: left;
                    }
                    
                    input {
                        background-color: var(--primary-light);
                        height: 50px;
                        border-radius: 50px;
                        padding: 20px;
                    }
                    
                    .forgot-password {
                        color: var(--primary-dark);
                        text-align: right;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    
                    .submit-button {
                        margin-top: 0px;
                        width: 100%;
                    }
                    
                    .danger-button {
                        margin-top: 0px;
                        width: 100%;
                        background-color: var(--alert-color);
                        color: white;
                    }
                    
                }
            }
        }
    }
}

.full-screen {
    .modal-dialog {
        --bs-modal-width: 95%;
        --bs-modal-height: 95%;
        
        
        .modal-content {
            min-height: 90vh;
            padding: 20px;
            
            .modal-header {
                .heading {
                    position: relative;
                    top: -4px;
                    margin-right: 10px;
                }
                
                .btn-close {
                    position: relative;
                    top: -14px;
                }
            }
            
            
            .copy-field {
                width: 500px;
                border-radius: 50px;
                background-color: white;
                border: 1px solid rgb(198, 194, 194);
                
                // height: 70px;
                input {
                    background-color: transparent;
                    padding-top: 0;
                    padding-bottom: 0;
                }
                
                button {
                    border-radius: 50px !important;
                    background-color: var(--primary-darker);
                    color: white;
                    height: 50px;
                    margin: 5px;
                    border: none;
                }
                
                
            }
            
            .group-box {
                align-items: center;
                justify-content: center;
                
                .each-group {
                    min-height: 225px;
                    
                    .title {
                        font-size: 2rem;
                        
                    }
                    
                    .names {
                        li {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}


.medium-screen {
    .modal-dialog {
        --bs-modal-width: 65%;
        --bs-modal-height: 65%;
        
        
        .modal-content {
            // height: 90vh;
            padding: 20px;
        }
    }
}

.sharable-modal {
    .modal-content {
        background-color: var(--box-bg-color);
    }
}

.login-register-modal {
    
    .dont-have-account,
    .already-have-account {
        margin-top: 15px;
        text-align: center;
        
        .click-here {
            text-decoration: underline;
            color: var(--primary-dark);
            display: inline-block;
            cursor: pointer;
        }
    }
}

.invite-students-modal {
    .box {
        padding: 50px;
        
        .description {
            font-size: 30px;
        }
        
        .ready-button {
            margin-top: 10px;
            font-size: 20px;
            padding: 15px 50px;
        }
    }
    
    .box-2 {
        
        .sides {
            display: flex;
            // justify-content: space-around;
            position: relative;
            justify-content: center;
            
            .left-side {
                text-align: center;
                // display: inline-block;
                // padding: 20px;
            }
            
            .double-side {
                text-align: center;
                // display: inline-block;
                width: 180px;
                position: relative;
                top: -15px;
            }
            
            @media (max-width:1500px) {
                // flex-direction: column;
                // align-items: center;
                .double-side {
                    
                    top: 15px;
                   
                }
                
            }

            @media (max-width:991px) {
                flex-direction: column;
                align-items: center;
                .double-side {
                    
                    top: 0;
                    align-items: center;
                   
                }
                
            }
            
        }
        
        .or-text {
            position: relative;
            // top: 110px;
            color: #282828;
            font-weight: 500;
        }
        
        .heading {
            font-size: 35px;
            font-weight: 500;
        }
        
        .code {
            
            background-color: var(--primary-light);
            // padding-top: 15px;
            // padding-bottom: 15px;
            font-size: 50px;
            // width: 300px;
            // height: 150px;
            margin: auto;
            text-align: center;
            margin-top: 20px;
            font-weight: bold;
        }
        
        .waiting-for-students {
            margin-top: 5px;
            font-size: 20px;
            color: rgb(9, 9, 189);
            animation: blink 1.5s infinite;
            font-weight: 500;
            /* Adjust the duration as needed */
        }
        
        .students {
            margin-top: 10px;
            background-color: var(--box-bg-color);
            border-radius: 20px;
            padding: 25px;
            min-height: 300px;
            overflow-y: scroll;
            
            .header {
                display: flex;
                justify-content: space-between;
                
                .student-joined {
                    display: flex;
                    
                    .icon {
                        width: 30px;
                    }
                    
                    .number {
                        font-size: 30px;
                        // font-weight: bold;
                    }
                }
            }
            
            .body {
                margin-top: 20px;
                list-style-type: none;
                padding: 0;
                
                .each-name {
                    font-size: 22px;
                    margin-top: 10px;
                }
            }
        }
    }
    
    .submit-button {
        margin-top: 10px;
        display: block;
        margin-left: auto;
    }
    
    .close-button-confirm {
        
        .btn-close {
            background-color: white;
            
            &:after {
                display: none;
            }
        }
        
        .confirm {
            color: var(--primary-darker);
            text-decoration: underline;
            cursor: pointer;
        }
        
        .dropdown-item {
            cursor: default;
            
            &:active {
                
                color: black;
                background-color: #f8f9fa;
            }
        }
    }
}

.feedback-modal {
    .heading {
        margin-bottom: 0;
    }
    
    .form-select,
    .form-control {
        background-color: var(--primary-light);
        height: 50px;
        border-radius: 50px;
        padding: 0 20px;
    }
    
    textarea.form-control {
        border-radius: 20px;
        resize: none;
        padding: 20px 20px;
        height: 151px;
    }
    
    .form-check {
        .form-check-input {
            height: 10px !important;
            padding: 10px !important;
            border-radius: 2px !important;
            background-color: white !important;
            
            &:checked {
                background-color: #0d6efd !important;
            }
        }
        
        .form-check-label {
            text-align: left;
            margin-left: 10px;
        }
    }
}
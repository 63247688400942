    .group-box {

        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        .each-group {
            border-radius: 10px;
            background-color: white;
            text-align: center;

            width: 22.5%;
            box-sizing: border-box;

            margin: 10px;
            padding: 15px;
            min-height: 200px;
            position: relative;
            overflow: hidden;

            @media(max-width:767px) {
                width: 100%;
            }

            &::before {
                content: "";
                display: block;
                width: 80%;
                border-top: 9px solid black;
                border-radius: 10px;
                position: relative;
                top: -17px;
                margin: auto;
            }

            &:nth-child(1) {
                &::before {
                    border-color: #FF5F5F;
                }
            }

            &:nth-child(2) {
                &::before {
                    border-color: #625FFF;
                }
            }

            &:nth-child(3) {
                &::before {
                    border-color: #5FFF6F;
                }
            }

            &:nth-child(4) {
                &::before {
                    border-color: #FFBF5F;
                }
            }

            &:nth-child(5) {
                &::before {
                    border-color: #03af8d;
                }
            }

            &:nth-child(6) {
                &::before {
                    border-color: #bbddff;
                }
            }

            &:nth-child(7) {
                &::before {
                    border-color: #004b93;
                }
            }

            &:nth-child(8) {
                &::before {
                    border-color: #45a3ff;
                }
            }

            &:nth-child(9) {
                &::before {
                    border-color: #e2f1ff;
                }
            }

            &:nth-child(10) {
                &::before {
                    border-color: #578a57;
                }
            }

            &:nth-child(11) {
                &::before {
                    border-color: #6e5ef8;
                }
            }

            &:nth-child(12) {
                &::before {
                    border-color: #69093e;
                }
            }

            &:nth-child(13) {
                &::before {
                    border-color: #04352e;
                }
            }

            &:nth-child(14) {
                &::before {
                    border-color: #083504;
                }
            }

            &:nth-child(15) {
                &::before {
                    border-color: #72e268;
                }
            }

            &:nth-child(16) {
                &::before {
                    border-color: #ab68e2;
                }
            }

            &:nth-child(17) {
                &::before {
                    border-color: #710ac5;
                }
            }


            .title {
                margin-top: 15px;
                font-weight: bold;
                font-size: 1.3rem;
            }

            .names {
                margin-top: 20px;
                list-style-type: none;
                padding: 0;

                li {
                    margin-top: 10px;
                    text-align: left;
                    font-size: 1.3rem;
                }
            }
        }
    }
.generator-section {

    .groups-section {
        background-color: var(--box-bg-color);
        padding: 20px;
        border-radius: 15px;

        max-height: 700px;
        overflow-y: scroll;



        @media (max-width:767px) {
            margin-top: 20px;

            max-height: auto;
            overflow-y: scroll;
        }

        .header {
            display: flex;
            justify-content: space-between;

            .left-side {
                margin-left: 15px;
                display: flex;
                align-items: baseline;

                .title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .options {
                    margin-left: 10px;
                    background-color: var(--primary-light);
                    padding: 6px;
                    border-radius: 50px;

                    .boxes-button,
                    .list-button,
                    .text-button {
                        background-color: transparent;
                        border: none;
                        width: 40px;
                        height: 40px;

                        &.active {
                            background-color: var(--primary);
                            border-radius: 50px;
                        }
                    }


                }

            }

            .right-side {
                .full-screen-button {
                    background-color: transparent;
                    border: none;
                    background-color: var(--primary-light);
                    border-radius: 50px;
                    border: 1px solid var(--primary);
                    width: 40px;
                    height: 40px;
                    margin-top: 6px;

                    &.disabled {
                        opacity: 0.3;
                        cursor: default;
                    }

                    .icon {
                        position: relative;
                        top: -1px
                    }
                }
            }
        }


        .table-box {
            margin-top: 15px;
            padding: 20px;
            background-color: white;
            border-radius: 10px;

            .each-table {
                display: flex;
                margin-top: 15px;


                @media (max-width:767px) {
                    display: block;
                }


                .title {
                    margin-right: 20px;
                    width: 150px;
                    font-size: 1.1rem;
                    font-weight: bold;
                }

                table {
                    width: 100%;
                    border-radius: 20px;
                    background-color: #f4f3e8;

                    thead {
                        th {
                            padding: 0 20px;
                            background-color: white;
                        }
                    }

                    tbody {

                        tr {
                            border-bottom: 1px solid #DAD1D1;


                            td {
                                padding: 20px;

                                // background-color: #f4f3e8;
                            }

                            &:last-child {
                                border-bottom: none;
                            }

                        }
                    }

                    .td-first {
                        width: 200px;
                    }

                    .td-second,
                    .td-third,
                    .td-forth {
                        width: 105px;

                        &.active {
                            // background-color: lightgrey;
                            background-color: #dfdfdd;
                        }
                    }

                    th.td-first {
                        padding-left: 16px;
                        padding-right: 0px;

                    }

                    // th.td-second, 
                    // th.td-third,
                    // th.td-forth,
                    
                    // {
                    //     padding: 0;
                    // }
                }
            }
        }

        .no-data-exists {
            text-align: center;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
}
.form-control,.form-select{
    border: none;
    &:focus{
        box-shadow: none;
    }
}
.textrea.form-control{
    border-radius: 15px;
}

.error-message{
    margin-top: 10px;
    color:rgb(192, 3, 3);
    text-align:center;
}
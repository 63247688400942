.join-page{
    background-color: var(--box-bg-color);
    height: 100vh;
    padding-top: 100px;
    
    @media (max-width:767px) {
        padding-top: 50px;
    }
    
  
    .box{
     
        width: 400px;
        border-radius: 20px;
        margin: auto;
        background-color: white; 
        padding: 20px;
        // height: 200px;

        margin-top: 30px;
        .each-field{
            margin-top: 10px;
            .form-control{
                margin-top: 0px;
                background-color: rgb(247 247 247) !important;
                height: 50px;
                border-radius: 50px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .submit-button{
            margin-top: 15px;
            width: 100%;
        }

        .success-message{
            padding-top: 15px;
            padding-bottom: 15px;
            .link{
                color: var(--primary-darker);
            }
        }
        
    }

}
.searchBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  #searchQueryInput {
    width: 100%;
    max-width: 600px;
    background: #f5f5f5;
    outline: none;
    border: none;
    border-radius: 50px;
    padding: 17px 30px;
    font-size: 22px;
  }

  #searchQuerySubmit {
    width: 60px;
    height: 60px;
    align-items: center;
    background-color: #DAFF8C;
    margin-left: -64px;
    border: none;
    border-radius: 50%;
  }

  #searchQuerySubmit:hover {
    cursor: pointer;
  }

.generator-section {
    .classes-section {
        background-color: var(--box-bg-color);
        padding: 20px;
        border-radius: 15px;

        max-height: 700px;
        overflow-y:scroll;

        @media (max-width:767px) {
            margin-top: 20px;

            max-height: auto;
            overflow-y:scroll;
        }

        .header {
            display: flex;
            justify-content: space-between;
            height: 52px;
            align-items: center;

            @media (max-width:767px) {
                display: block;
            }

            .left-side {
                margin-left: 15px;
                display: flex;
                align-items: baseline;

                .title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .options {
                    margin-left: 10px;
                    background-color: var(--primary-light);
                    padding: 6px;
                    border-radius: 50px;

                    .boxes-button,
                    .list-button,
                    .text-button {
                        background-color: transparent;
                        border: none;
                        width: 40px;
                        height: 40px;

                        &.active {
                            background-color: var(--primary);
                            border-radius: 50px;
                        }
                    }


                }

            }

            .right-side {


                @media (max-width:767px) {
                    text-align: center;
                    margin-top: 15px;
                }


                .edit-attributes-button {
                    background-color: transparent;
                    border: none;
                    color: black;
                    position: relative;
                    top: -5px;
                    margin-right: 10px;

                    .icon {
                        position: relative;
                        top: -1px;
                    }

                    &.disabled {
                        opacity: 0.3;
                        cursor: default;
                    }
                }

                .delete-students-button {
                    background-color: transparent;
                    border: none;
                    color: var(--alert-color);
                    position: relative;
                    top: -5px;

                    .icon {
                        position: relative;
                        top: -1px;
                    }

                    &.disabled {
                        opacity: 0.3;
                        cursor: default;
                    }
                }

            }
        }

        .classes-box {
            padding-left: 20px;
            padding-right: 20px;

            @media (max-width:767px) {
                
                padding: 0;
                margin-top: 20px;

                width: 200%;
                overflow-x: scroll;
                
            }


            .table-hover {

            
                thead {
                    tr {

                        th {
                            background-color: var(--primary);
                            padding-top: 15px;
                            padding-bottom: 15px;

                            &:first-child {
                                width: 70px;
                            }
                        }

                        .header-checkbox {
                            margin-left: 10px;
                        }

                    }
                }

                tbody {

                    tr {
                        border-bottom: none;

                        td {
                            border-bottom: none;

                            .person-name,
                            .attr1,
                            .attr2,
                            .attr3 {
                                background-color: #F4F4F4;
                                height: 45px;
                                border: 1px solid #F4F4F4;
                                &:focus{
                                    border: 1px solid #90b37e;
                                }
                            }

                            .each-checkbox {
                                margin-left: 10px;
                                margin-top: 10px;
                            }

                        }

                        &:hover {
                            td {
                                background-color: #e5e5e5;
                                box-shadow: none;
                            }
                        }


                    }

                }

                margin-bottom: 0;
            }

            .enable-paste-data-section {
                background-color: white;
                padding: 20px;

                .content {
                    background-color: var(--primary-light);
                    padding: 30px;
                    text-align: center;
                    border-radius: 10px;

                    .click-here {
                        cursor: pointer;
                        color: var(--primary-darker);
                        text-decoration: underline;
                    }
                }
            }

            .paste-data-section {
                background-color: white;
                padding: 20px;

                .content {
                    border: 4px dashed var(--primary-darker);
                    background-color: white;
                    padding: 15px;
                    text-align: center;
                    border-radius: 10px;

                    .click-here {
                        cursor: pointer;
                        color: var(--primary-darker);
                        text-decoration: underline;
                    }
                }
            }
        }

    }

    .no-class-selected-section {
        // background-color: white;
        padding: 35px;
        border-radius: 10px;
        text-align: center;
    }
}
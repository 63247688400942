.generator-section {
    .sidebar-section {
        .toggle-button {
            background-color: var(--primary-light);
            height: 50px;
            display: flex;
            border-radius: 50px;

            button {
                height: 50px;
                width: 50%;
                border: none;
                font-weight: bold;
                background-color: transparent;

                &.active {
                    background-color: var(--primary);
                    border-radius: 50px;
                }
            }

            margin-bottom: 10px;
        }

        .custom-form {
            background-color: var(--box-bg-color);
            padding: 20px;
            border-radius: 10px;

            label {
                font-weight: bold;
            }

            .student-list {
                cursor: auto;
                height: 200px;
                overflow-y: scroll;
                resize: none;
            }

            .group-size,.select-attributes,.choose-class {
                height: 50px;
                border-radius: 50px;
            }

            .shuffle-button,
            .save-button {
                width: 100%;
                &[disabled]
                {
                    opacity: 0.5;
                }
            }

            .shuffle-button {
                .shuffle-icon {
                    margin-right: 10px;
                    position: relative;
                    top: -1px;
                }
            }

            .save-button {
                border: none;
                background-color: transparent;
                color: var(--primary-dark);

                .save-icon {
                    margin-right: 10px;
                    position: relative;
                    top: -1px;
                }
            }
        }

        .show-classes {

            .all-classes {
                max-height: 500px;
                overflow-y: scroll;
                padding: 5px;

                .each-class {

                    display: flex;
                    justify-content: space-between;

                    margin-top: 10px;
                    background-color: white;
                    height: 50px;
                    padding: 12px 15px;
                    border-radius: 5px;
                    cursor: pointer;

                    &.active {
                        background-color: var(--primary);
                    }

                    .title {
                        width: 90%;
                    }

                    .dots{

                        background-color: transparent;
                        padding: 0;

                        .icon{
                            right: -8px;
                            position: relative;
                        }
                        &::after{
                            display: none;
                        }
                    }

                    .rename-class,.delete-class{

                        &:active{
                            background-color: #f8f9fa;
                            color: #000;
                        }
                    }
                    .rename-class{
                        img{
                            width: 20px;
                        }
                    }
                    .delete-class{
                        img{
                            width: 20px;
                            position: relative;
                            top: -3px;
                        }
                    }
                }
            }

            .add-class-button {
                margin-top: 10px;
                color: var(--primary-dark);
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
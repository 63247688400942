.generator-section {
    .header-section {
        margin-top: 50px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .title {
            font-size: 2rem;
        }

        .invite-students-button {
            background-color: black;
            width: 50px;
            height: 50px;
            border-radius: 200px;

            .icon {
                width: 35px;

            }
        }
    }

}


.feedback-button {
    z-index: 1000;
    cursor: pointer;
    position: fixed;
    bottom: 25px;
    right: 25px;
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    border-radius: 50px;
    text-align: center;

    .icon {
        width: 30px;
        position: relative;
        top: 10px
    }
}
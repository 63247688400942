.logo {
  width: 150px;
}

.user-dropdown {
  button {
    background-color: white !important;
    color: #000 !important;
    padding: 0;
    &:active {
      color: #000;
    }
  }
  .dropdown-item {
    &:active {
      background-color: #e3e3e3 !important;
      color: #000;
    }
  }
}

.pagination,
.page-numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.pagination {
  margin-top: 40px;
}

.btn-nav,
.btn-page {
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.btn-nav {
  width: 50px;
  height: 50px;
  border: none;
  background-color: rgba(236, 233, 228, 0.5);
}

.btn-page {
  border: none;
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
}

.btn-page:hover {
    background-color: #c3f65d;
    transition: all .2s;
}

.btn-selected {
  background-color: #DAFF8C;
}

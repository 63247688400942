.btn-primary {
    background-color:var(--primary);
    color: #000;
    // font-size: 20px;
    border: none;
    padding: 12px 30px;
    font-weight: 600;
    border-radius: 50px;
    &:hover,&:active{
        opacity: 0.8;
        color: #000;
        transition: all .2s;
        background-color:var(--primary);
    }

}

.btn-brighter-primary {
    background-color:var(--primary-bright);
    color: #000;
    // font-size: 20px;
    border: none;
    padding: 12px 30px;
    font-weight: 600;
    border-radius: 50px;
    &:hover {
        opacity: 0.8;
        color: #000;
        transition: all .2s;
    }
    &[disabled]{
        background-color: rgb(236, 236, 236);
        opacity: 0.5;
    }
}

.custom-scrollbar{
    &::-webkit-scrollbar {
        width: 10px;
     
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border:1px solid rgb(236, 236, 236);
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--primary);
        cursor: pointer;
    }
}


.no-visible-scrollbar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }
}

.information-section {
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width:767px) {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .text-box {
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        margin-top: 10px;
        display: flex;
        .green-check {
          width: 25px;
        }
        p {
          margin-left: 5px;
        }
      }
    }
  }
  .people-talking {
    width: 100%;
  }
}

.margin-bottom-medium {
    margin-bottom: 30px;
}

.margin-bottom-big {
    margin-bottom: 50px;
}

p {
    margin-bottom: 0;
}

.row {
    box-sizing: border-box;
}

// .col-lg-4 {
//     max-width: 420px;
// }

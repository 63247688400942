.label-container {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 22px;
    white-space: nowrap;
  }
  
  .label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #C9D3CC;
    border-radius: 3px;
  }
  
  .label-container input:checked ~ .checkmark {
    background-color: #BFDDA3;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .label-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .label-container .checkmark:after {
    left: 9px;
    top: 2px;
    width: 6px;
    height: 14px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  